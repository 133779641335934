import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import OTP from "./components/otp-modal";
import useDebounce from "./useDebounce";
import Loader from "./components/loader";

import Logo from "./images/Logo.png";
import Appstore from "./images/Appstore.png";
import Googlestore from "./images/Googleplay.png";

const Validate = () => {
  const [nameEnquiry, setNameEnquiry] = useState({
    AccountNumber: "",
    DIC: "090325",
  });

  const [message, setMessage] = useState("");
  const [accountData, setAccountData] = useState(null);
  const [isSearching, setIsSearching] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const [popup, setPopup] = useState(false);

  const debouncedSearchTerm = useDebounce(nameEnquiry.AccountNumber, 500);

  const hidePopup = () => {
    setPopup(false);
  };

  const reg = /.{1,9}/;

  // Request OTP function
  function requestOtp(param) {
    return fetch("https://apigateway.sparkleapp.in/api/v1/otp/send", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        nuban: param,
      }),
    })
      .then((r) => r.json())
      .then((r) => {
        setPopup(true);
      })
      .catch((error) => {
        console.error(error);
        return [];
      });
  }

  const handleAccountChange = (evt) => {
    const value = evt.target.value;
    setNameEnquiry({
      ...nameEnquiry,
      [evt.target.name]: value,
    });
  };

  // Effect for API call
  useEffect(
    () => {
      if (debouncedSearchTerm && nameEnquiry.AccountNumber.length > 9) {
        setIsLoading(true);
        setMessage("");
        fetch(
          "https://apigateway.sparkleapp.in/api/v1/paymentlinks/name-enquiry",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              AccountNumber: debouncedSearchTerm,
              DIC: "090325",
            }),
          }
        )
          .then((r) => r.json())
          .then((r) => {
            if (r.status === "success") {
              setAccountData(r.data);
              setMessage("");
              setIsSearching(true);
              setIsLoading(false);
            } else {
              setAccountData(null);
              setMessage(r.message);
              setIsSearching(false);
              setIsLoading(false);
            }
          })
          .catch((error) => {
            console.error(error);
            return [];
          });
      } else {
        setAccountData(null);
        setIsSearching(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedSearchTerm] // Only call effect if debounced search term changes
  );

  return (
    <>
      <div className="container-fluid onboarding px-lg-0">
        <div className="row justify-content-center no-gutters">
          <div className="col-lg-4 offset-lg-1">
            <div className="p-lg-5 p-2 onboarding__left">
              <Link to="/">
                <img
                  src={Logo}
                  alt="Sparkle Website"
                  className="img-fluid"
                  width="180"
                />
              </Link>

              <div className="py-lg-5 py-3">
                <h1 className="sparkle--mont__900 mt-lg-5 mt-3">
                  Simple. <br /> Smart.
                  <br /> <span className="sparkle--color__green">Sparkle.</span>
                </h1>
                <p className="mb-lg-5 mb-3">
                  Sparkleplus gives business owners a new and easy way to
                  receive payments from customers and makes room from customers
                  to make payment to your business.
                </p>

                <p className="text-center pt-lg-5 pt-3">
                  I don’t have a Sparkle Account
                </p>

                <div className="d-flex">
                  <div>
                    <Link to="">
                      <img src={Appstore} alt="" className="img-fluid" />
                    </Link>
                  </div>
                  <div>
                    <Link to="">
                      <img src={Googlestore} alt="" className="img-fluid" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 offset-lg-1">
            <div className="onboarding__right h-100">
              <p className="text-center sparkle--color__deep-blue heading my-lg-5 mt-3">
                Let’s have your Sparkle Account.
              </p>

              <form className="__form mt-lg-5 pt-4 mt-3 mb-5 text-left">
                <div className="form-row align-items-center">
                  <div className="col-md-12 my-2">
                    <label htmlFor="AccountNumber">
                      Sparkle Account Number
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="AccountNumber"
                      name="AccountNumber"
                      onChange={handleAccountChange}
                      value={nameEnquiry.AccountNumber.slice(0, 10)}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </div>

                  <div className="col-lg-12">
                    <p className="sparkle--color__bright-orange mt-3">
                      <b>
                        {accountData &&
                          Object.keys(accountData).length !== 0 &&
                          accountData.AccountName.replace(reg, (m) =>
                            "X".repeat(m.length)
                          )}
                      </b>
                    </p>

                    <span className="text-danger">{message}</span>
                  </div>
                </div>
                {isLoading && <Loader width="50" color="#46b839" />}

                <button
                  type="button"
                  className="btn btn-block sparkle--button__green px-lg-5 my-5"
                  onClick={() => requestOtp(nameEnquiry.AccountNumber)}
                  disabled={!isSearching}
                >
                  Validate
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      <OTP
        show={popup}
        closePopup={() => hidePopup()}
        requestNewOtp={() => requestOtp(nameEnquiry.AccountNumber)}
        account={nameEnquiry.AccountNumber}
        // path={state.path}
      />
    </>
  );
};

export default Validate;
