import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import OtpInput from "react-otp-input";

import Loader from "../loader";

const OTP = (props) => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [message, setMessage] = useState("");
  const [state, setState] = useState({
    isButtonDisabled: false,
  });
  const handleClose = () => props.closePopup();

  const handleChange = (otp) => setOtp(otp);

  const verifyOtp = async () => {
    setIsLoading(true);
    await fetch("https://apigateway.sparkleapp.in/api/v1/otp/verify", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        nuban: props.account,
        otp: otp,
      }),
    })
      .then((r) => r.json())
      .then((r) => {
        if (r.status === "success") {
          setMessage(r.data);
          setIsLoading(false);

          window.location.replace("http://biz.interad.online/qr/a72x23W");
          // dispatch(notify(r.data, "success"));
          localStorage.setItem(
            "6d361f1d1c1160c1f",
            JSON.stringify(process.env.REACT_APP_ENCRYPT)
          );
          navigate("/register");
        } else setMessage(r.message);
      })
      .catch((error) => {
        setMessage(error.message);
        setIsLoading(false);
        console.error(error);
        return [];
      });
  };

  return (
    <div>
      <Modal size="lg" show={props.show} onHide={props.closePopup} centered>
        <Modal.Body className="p-0 rounded-20 position-relative">
          <span
            aria-label="close"
            className="modal-close-btn"
            onClick={() => handleClose()}
            onKeyDown={() => handleClose()}
            role="button"
            tabIndex={0}
          >
            <svg
              width="35"
              height="35"
              viewBox="0 0 35 35"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="17.5" cy="17.5" r="17.5" fill="#14233B" />
              <path
                d="M11.375 23.625L16.4427 17.3475L11.6608 11.375H13.6326L17.5 16.2624L21.3388 11.375H23.3297L18.5478 17.3296L23.625 23.625H21.6532L17.5 18.4057L13.3373 23.625H11.375Z"
                fill="white"
              />
            </svg>
          </span>

          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-9">
                <h3 className="text-center font-weight-bold mt-5 sparkle--color__light">
                  OTP Validation
                </h3>

                <p className="sparkle--color__light text-center mt-3">
                  Input the security code sent to your device with your Sparkle
                  Account.
                </p>

                <p className="small mt-3 text-danger text-center">{message}</p>

                <form className="kwes-form waitlist__form my-lg-5 my-3">
                  <div className="form-row justify-content-center">
                    <div className="col">
                      <OtpInput
                        value={otp}
                        onChange={handleChange}
                        numInputs={6}
                        separator={<span>-</span>}
                        isInputNum
                        inputStyle="form-control inputStyle"
                        containerStyle="form-row justify-content-center"
                      />
                    </div>
                  </div>

                  {isLoading && <Loader width="50" color="#46b839" />}

                  <div className="form-row justify-content-center mt-3">
                    <div className="col-lg-8">
                      <button
                        className="btn sparkle--button__bright-lime px-5 mt-4 btn-block mb-0"
                        onClick={() => verifyOtp()}
                        disabled={otp.length < 6}
                        type="button"
                      >
                        Proceed
                      </button>

                      <p className="sparkle--color__light text-center mt-4">
                        I didn’t receive OTP,{" "}
                        {state.isButtonDisabled ? (
                          <span className="sparkle-green">
                            Try again in 15 secs.
                          </span>
                        ) : (
                          <span
                            className="sparkle--color__green cursor"
                            onClick={() => {
                              props.requestNewOtp(props.account);
                              setOtp("");
                              setState({
                                isButtonDisabled: true,
                              });
                              setTimeout(
                                () => setState({ isButtonDisabled: false }),
                                15000
                              );
                            }}
                          >
                            <b>Send Again</b>
                          </span>
                        )}
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default OTP;
